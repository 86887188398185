import { SVGProps, Ref, forwardRef } from 'react'

const SvgMoneyBag = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.573 12.75H11.54a1.342 1.342 0 0 0-.5 2.587l2.064.826a1.342 1.342 0 0 1-.5 2.587h-2.03M12.074 12.75V12M12.074 19.5v-.75M6.824 8.25h10.5M15.63 5.036l1.438-2.876a.75.75 0 0 0-1.006-1.006l-1.9.948a.75.75 0 0 1-.959-.255l-.503-.763a.75.75 0 0 0-1.248 0l-.509.763a.749.749 0 0 1-.959.255l-1.897-.948A.751.751 0 0 0 7.08 2.16l1.41 2.82"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.236 10.5-2.76 3.2a5.932 5.932 0 0 0 4.7 9.549h5.79a5.932 5.932 0 0 0 4.7-9.549l-2.76-3.2"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgMoneyBag)
export default ForwardRef
